<template>
  <v-container>
    <SessionComplete />
    <!--    <SessionCreditsFooter />-->
  </v-container>
</template>

<script>
import { projectCustomComponent } from '@utils/project.js'

export default {
  page: {
    title: 'Sessione Conclusa!',
  },
  components: {
    // SessionCreditsFooter: () =>
    //   projectCustomComponent('SessionCreditsFooter.vue'),
    SessionComplete: () => projectCustomComponent('SessionComplete.vue'),
  },
  props: ['sid', 'project'],
}
</script>
